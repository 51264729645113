<template>
  <v-container>
    <base-material-alert v-if="validationErrors.length > 0" color="error" dark>
      <p v-for="error in validationErrors" :key="error">{{ error }}</p>
    </base-material-alert>

    <base-material-alert v-if="userSaved" color="success" dark>{{
      $t("user_saved_successfully")
    }}</base-material-alert>
    <v-row>
      <v-col cols="12" class="ml-3"
        ><v-btn
          v-if="$store.getters['auth/isStudio']"
          color="primary"
          @click.native="$router.back()"
          style="height: 30px"
          outlined
        >
          <v-icon>mdi-arrow-left</v-icon>
          {{ $t("back") }}
        </v-btn></v-col
      >
      <v-col>
        <base-material-card
          icon="mdi-file-document-box-multiple-outline"
          :title="$t('add_user')"
        >
          <v-card>
            <v-card-text>
              <users-form
                title="add_user"
                :user="user"
                @validated="saveUser(user)"
                :buttons="true"
                :roles="!$store.getters['auth/isAdmin']"
                :show_cash_registers="$store.getters['auth/isStudio']"
              ></users-form>
            </v-card-text>
          </v-card>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UsersForm from "@/components/user/Form";
import constants from "@/constants";
import { mapActions } from "vuex";
export default {
  name: "NewUserForm",
  components: { UsersForm },
  mounted() {
    if (this.$store.getters["auth/isAdmin"]) {
      this.user.role_id = constants.roles.ADMIN;
    }
    if (this.$store.getters["auth/isStudio"]) {
      this.user.studio_id = this.$store.state.auth.user.id;
    }
  },
  data() {
    return {
      userSaved: false,
      validationErrors: [],
      user: {
        name: null,
        email: null,
        role_id: null,
        studios_ids: [],
        cash_registers_ids: [],
        can_see_cash_registers: null,
      },
    };
  },
  methods: {
    ...mapActions("users", ["addUser"]),
    saveUser(user) {
      this.addUser({ user_id: this.$route.params.id, user: this.user }).then(
        (response) => {
          if (response.success) {
            this.errors.clear();
            this.$alert(this.$t("user_create_success"), "", "");
            this.$router.back();
          } else {
            this.$alert(this.$t("used_email"), "", "");
          }
        }
      );
    },
  },
};
</script>